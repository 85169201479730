import { css } from "@emotion/react";
import React from "react";

import { Grid } from "@mui/material";

import { appColor } from "@/styles/app-colors";
import { mqMinScreen } from "@/utils/mediaQuery";

import { SUMMARY_CONTENTS } from "@/enums/dashboard-enums";
import { SvgIconArrowDown, SvgIconArrowRight, SvgIconArrowUp } from "@/svgs";
import { SalesSummaryByDate } from "@/types/dashboard-types";

type Props = {
  salesSummary: SalesSummaryByDate[];
  lastPeriodSalesSummary: SalesSummaryByDate[];
};

const Summary: React.FC<Props> = (props) => {
  const amountSum = props.salesSummary.reduce((acc, cur) => acc + cur.salesAmount, 0);
  const countSum = props.salesSummary.reduce((acc, cur) => acc + cur.salesCount, 0);
  const lastPeriodAmountSum = props.lastPeriodSalesSummary.reduce((acc, cur) => acc + cur.salesAmount, 0);
  const lastPeriodCountSum = props.lastPeriodSalesSummary.reduce((acc, cur) => acc + cur.salesCount, 0);
  const amountRate = Math.round(((amountSum - lastPeriodAmountSum) / lastPeriodAmountSum) * 1000) / 10;
  const countRate = Math.round(((countSum - lastPeriodCountSum) / lastPeriodCountSum) * 1000) / 10;

  return (
    <Grid css={rootStyled}>
      <Grid css={containerStyled}>
        <Grid css={titleStyled}>{SUMMARY_CONTENTS.salesAmount.name}</Grid>
        <Grid css={contentContainerStyled}>
          <Grid css={contentStyled}>
            <Grid css={numberStyled}>{amountSum.toLocaleString()}</Grid>
            <Grid css={unitStyled}>{SUMMARY_CONTENTS.salesAmount.unit}</Grid>
          </Grid>
          <Grid css={rateBoxStyled}>
            {lastPeriodAmountSum === 0 ? (
              <>
                <svg width={12} height={12} />
                <Grid css={rateStyled(0)}>-</Grid>
              </>
            ) : (
              <>
                {amountRate > 0 ? (
                  <SvgIconArrowUp fill={appColor.Text.mossGreen} />
                ) : amountRate < 0 ? (
                  <SvgIconArrowDown fill={appColor.Text.lightRed} />
                ) : (
                  <SvgIconArrowRight />
                )}
                <Grid css={rateStyled(amountRate)}>{amountRate}%</Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid css={containerStyled}>
        <Grid css={titleStyled}>{SUMMARY_CONTENTS.salesCount.name}</Grid>
        <Grid css={contentContainerStyled}>
          <Grid css={contentStyled}>
            <Grid css={numberStyled}>{countSum.toLocaleString()}</Grid>
            <Grid css={unitStyled}>{SUMMARY_CONTENTS.salesCount.unit}</Grid>
          </Grid>
          <Grid css={rateBoxStyled}>
            {lastPeriodCountSum === 0 ? (
              <>
                <svg width={12} height={12} />
                <Grid css={rateStyled(0)}>-</Grid>
              </>
            ) : (
              <>
                {countRate > 0 ? (
                  <SvgIconArrowUp fill={appColor.Text.mossGreen} />
                ) : countRate < 0 ? (
                  <SvgIconArrowDown fill={appColor.Text.lightRed} />
                ) : (
                  <SvgIconArrowRight />
                )}
                <Grid css={rateStyled(countRate)}>{countRate}%</Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(Summary);

const rootStyled = css`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  ${mqMinScreen("xl")} {
    flex-direction: column;
    flex: 1 0 0;
  }
`;

const containerStyled = css`
  flex: 1 0 0;
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #ebeef1;
`;

const titleStyled = css`
  color: ${appColor.Text.darkGray};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  letter-spacing: -0.8px;
`;

const contentContainerStyled = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
`;

const contentStyled = css`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

const numberStyled = css`
  color: ${appColor.Text.primary};
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 32px */
  letter-spacing: -1.6px;
`;

const unitStyled = css`
  color: ${appColor.Text.primary};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; /* 27.6px */
  letter-spacing: -1.2px;
`;

const rateBoxStyled = css`
  width: 100px;
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 14px;
  border: 1px solid #000;
`;

const rateStyled = (rate: number) => css`
  color: ${rate > 0 ? appColor.Text.mossGreen : rate < 0 ? appColor.Text.lightRed : appColor.Text.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
`;
