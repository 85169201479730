import { css } from "@emotion/react";
import React from "react";

import { Grid } from "@mui/material";

import { appColor } from "@/styles/app-colors";

import { SUMMARY_CONTENTS } from "@/enums/dashboard-enums";
import { SalesSummaryByDate } from "@/types/dashboard-types";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";

const CustomTooltip = (props: TooltipProps<number, string>) => {
  return <Grid css={tooltipContainerStyled}>{props.payload?.[0]?.value?.toLocaleString()}</Grid>;
};

type Props = {
  salesSummary: SalesSummaryByDate[];
  graphContent: keyof typeof SUMMARY_CONTENTS;
};

const Graph: React.FC<Props> = (props) => {
  return (
    <ResponsiveContainer width="99%">
      <AreaChart data={props.salesSummary} margin={{ top: 10, right: 30, left: 0, bottom: 0 }} css={chartStyled}>
        <defs>
          <linearGradient
            id="paint0_linear_3836_948"
            x1="1049"
            y1="73.9998"
            x2="-2.01103e-06"
            y2="73.9997"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8F80FF" stopOpacity="0.2" />
            <stop offset="0.28125" stopColor="#ED6FCC" stopOpacity="0.2" />
            <stop offset="0.5" stopColor="#F24EC4" stopOpacity="0.2" />
            <stop offset="0.838542" stopColor="#F0856E" stopOpacity="0.2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3836_948"
            x1="524.5"
            y1="0"
            x2="524.5"
            y2="147"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0" />
            <stop offset="0.838542" stopColor="white" stopOpacity="0.8" />
          </linearGradient>
          <linearGradient
            id="paint0_linear_3836_949"
            x1="1050"
            y1="30.0001"
            x2="0.971568"
            y2="34.8491"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8F80FF" />
            <stop offset="0.489583" stopColor="#FF52A1" />
            <stop offset="1" stopColor="#FFCC80" />
          </linearGradient>
        </defs>
        <XAxis dataKey="date" tickLine={{ stroke: "#D9D9D9" }} />
        <YAxis axisLine={false} tickLine={false} />
        <CartesianGrid horizontal vertical={false} stroke="#D9D9D9" strokeWidth={1} />
        <Tooltip content={CustomTooltip} />
        <Area
          type="monotone"
          dataKey={props.graphContent}
          stroke="url(#paint0_linear_3836_949)"
          strokeWidth={2}
          fill="url(#paint0_linear_3836_948)"
        />
        <Area type="monotone" dataKey={props.graphContent} strokeOpacity={0} fill="url(#paint1_linear_3836_948)" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default React.memo(Graph);

const chartStyled = css`
  & .recharts-text {
    font-size: 12px;
    fill: ${appColor.Text.darkGray};
  }
`;

const tooltipContainerStyled = css`
  display: inline-flex;
  padding: 6px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 14px;
  background: ${appColor.Background.black};
  color: ${appColor.Text.white};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
`;
