import { css } from "@emotion/react";
import React from "react";

import { Grid } from "@mui/material";

import { appColor } from "@/styles/app-colors";

import { SUMMARY_CONTENTS } from "@/enums/dashboard-enums";
import { SalesSummaryByDate } from "@/types/dashboard-types";

type Props = {
  summaryContent: keyof typeof SUMMARY_CONTENTS;
  salesSummary: SalesSummaryByDate[];
};

const SelectedSummary: React.FC<Props> = (props) => {
  const sum = props.salesSummary?.reduce((acc, cur) => acc + cur[props.summaryContent], 0);

  return (
    <Grid css={summaryStyled}>
      <Grid css={summaryTitleStyled}>{SUMMARY_CONTENTS[props.summaryContent].name}</Grid>
      <Grid css={summaryContentStyled}>
        <Grid css={summaryNumberStyled}>{sum.toLocaleString()}</Grid>
        <Grid css={summaryUnitStyled}>{SUMMARY_CONTENTS[props.summaryContent].unit}</Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(SelectedSummary);

const summaryStyled = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;
  align-self: stretch;
`;

const summaryTitleStyled = css`
  color: ${appColor.Text.darkGray};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
`;

const summaryContentStyled = css`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

const summaryNumberStyled = css`
  color: ${appColor.Text.primary};
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 40px */
`;

const summaryUnitStyled = css`
  color: ${appColor.Text.primary};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%; /* 27.6px */
  letter-spacing: -1.2px;
`;
