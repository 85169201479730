import { css } from "@emotion/react";
import { useRouter } from "next/router";
import React, { useState } from "react";

import { Grid, Skeleton, useMediaQuery, useTheme } from "@mui/material";

import { useApiConnector } from "@/custom-hooks/apis-common/use-api-connector";
import { mqMinScreen } from "@/utils/mediaQuery";

import DateRangePicker from "../molecules/DateRangePicker";
import Graph from "../molecules/Graph";
import GraphContentToggle from "../molecules/GraphContentToggle";
import SelectedSummary from "../molecules/SelectedSummary";
import Summary from "./Summary";

import { SUMMARY_CONTENTS } from "@/enums/dashboard-enums";
import { selectedSpotIdAtom } from "@/global-states/common-atoms";
import { DateRange, SalesSummaryByDate } from "@/types/dashboard-types";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useAtomValue } from "jotai";

const UpperSection: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const router = useRouter();
  const { sd, ed } = router.query as { sd: string; ed: string };
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate:
      sd && dayjs(sd, "YYYYMMDD").isValid()
        ? dayjs(sd, "YYYYMMDD").startOf("d").toDate()
        : dayjs().subtract(6, "d").startOf("d").toDate(),
    endDate:
      ed && dayjs(ed, "YYYYMMDD").isValid() ? dayjs(ed, "YYYYMMDD").endOf("d").toDate() : dayjs().endOf("d").toDate(),
  });
  const handleChangeDateRange = (value: Partial<DateRange>) => {
    const result = { ...dateRange, ...value };
    setDateRange({ ...result });
    return router.push({
      query: {
        sd: dayjs(result.startDate).format("YYYYMMDD"),
        ed: dayjs(result.endDate).format("YYYYMMDD"),
      },
    });
  };

  const selectedSpotId = useAtomValue(selectedSpotIdAtom);
  const { operatorPaymentApi } = useApiConnector();
  const { data, isSuccess } = useQuery({
    queryKey: ["operatorReservationApi.getReservationList", dateRange, { spotId: selectedSpotId }],
    queryFn: async () => {
      const diff = dayjs(dateRange.endDate).diff(dateRange.startDate, "d");
      const payments = await operatorPaymentApi.getPaymentList({
        spotId: selectedSpotId,
        limit: 100000,
        status: "paid,refunded",
        minEndAt: dayjs(dateRange.startDate)
          .subtract(diff + 1, "d")
          .unix()
          .toString(),
        maxEndAt: dayjs(dateRange.endDate).unix().toString(),
      });

      const reservations = payments.objects.map((payment) => {
        return {
          ...payment.reservation,
          payment: payment,
        };
      });

      // 日付ごとに集計
      const salesSummary = [...Array(diff + 1)].map((_, index) =>
        reservations
          .filter((reservation) =>
            dayjs.unix(reservation.payment.createdAt).isSame(dayjs(dateRange.startDate).add(index, "d"), "d"),
          )
          .reduce<SalesSummaryByDate>(
            (acc, cur) => ({
              date: acc.date,
              salesAmount: acc.salesAmount + cur.payment.amount - cur.payment.refundAmount,
              salesCount: acc.salesCount + cur.count,
            }),
            { date: dayjs(dateRange.startDate).add(index, "d").format("M/D"), salesAmount: 0, salesCount: 0 },
          ),
      );
      const lastPeriodSalesSummary = [...Array(diff + 1)].map((_, index) =>
        reservations
          .filter((reservation) =>
            dayjs.unix(reservation.payment.createdAt).isSame(
              dayjs(dateRange.startDate)
                .subtract(diff + 1, "d")
                .add(index, "d"),
              "d",
            ),
          )
          .reduce<SalesSummaryByDate>(
            (acc, cur) => ({
              date: acc.date,
              salesAmount: acc.salesAmount + cur.payment.amount - cur.payment.refundAmount,
              salesCount: acc.salesCount + cur.count,
            }),
            { date: dayjs(dateRange.startDate).add(index, "d").format("M/D"), salesAmount: 0, salesCount: 0 },
          ),
      );

      return { salesSummary, lastPeriodSalesSummary };
    },
  });

  const [graphContent, setGraphContent] = useState<keyof typeof SUMMARY_CONTENTS>("salesAmount");
  const toggleGraphContent = (newValue: keyof typeof SUMMARY_CONTENTS) => setGraphContent(newValue);

  return (
    <>
      {isMobile ? (
        <Grid css={spRootStyled}>
          <Grid css={spUpperSectionStyled}>
            {isSuccess ? (
              <SelectedSummary summaryContent={graphContent} salesSummary={data.salesSummary} />
            ) : (
              <Skeleton variant="rectangular" width={200} height={64} />
            )}
            <DateRangePicker dateRange={dateRange} handleChangeDateRange={handleChangeDateRange} />
          </Grid>
          <Grid css={graphAreaStyled}>
            <GraphContentToggle graphContent={graphContent} toggleGraphContent={toggleGraphContent} />
            {isSuccess ? (
              <Graph salesSummary={data.salesSummary} graphContent={graphContent} />
            ) : (
              <Skeleton variant="rectangular" width="100%" height="100%" />
            )}
          </Grid>
          {isSuccess ? (
            <Summary salesSummary={data.salesSummary} lastPeriodSalesSummary={data.lastPeriodSalesSummary} />
          ) : (
            <Skeleton variant="rectangular" width="100%" height={130} />
          )}
        </Grid>
      ) : (
        <Grid css={pcRootStyled}>
          <Grid css={pcLeftSectionStyled}>
            {isSuccess ? (
              <SelectedSummary summaryContent={graphContent} salesSummary={data.salesSummary} />
            ) : (
              <Skeleton variant="rectangular" width={200} height={64} />
            )}
            <Grid css={graphAreaStyled}>
              <GraphContentToggle graphContent={graphContent} toggleGraphContent={toggleGraphContent} />
              {isSuccess ? (
                <Graph salesSummary={data.salesSummary} graphContent={graphContent} />
              ) : (
                <Skeleton variant="rectangular" width="100%" height="100%" />
              )}
            </Grid>
          </Grid>
          <Grid css={pcRightSectionStyled}>
            <DateRangePicker dateRange={dateRange} handleChangeDateRange={handleChangeDateRange} />
            {isSuccess ? (
              <Summary salesSummary={data.salesSummary} lastPeriodSalesSummary={data.lastPeriodSalesSummary} />
            ) : (
              <Skeleton variant="rectangular" width="100%" height={284} />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default React.memo(UpperSection);

const spRootStyled = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`;

const spUpperSectionStyled = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const pcRootStyled = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 40px;
  align-self: stretch;
`;

const pcLeftSectionStyled = css`
  flex: 2 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  padding-top: 40px;
`;

const graphAreaStyled = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  height: 220px;
  ${mqMinScreen("xl")} {
    height: 100%;
  }
`;

const pcRightSectionStyled = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  max-width: 480px;
`;
