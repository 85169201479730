import { css } from "@emotion/react";
import React from "react";

import { Grid } from "@mui/material";

import { appColor } from "@/styles/app-colors";

import { SUMMARY_CONTENTS } from "@/enums/dashboard-enums";

type Props = {
  graphContent: keyof typeof SUMMARY_CONTENTS;
  toggleGraphContent: (newValue: keyof typeof SUMMARY_CONTENTS) => void;
};

const GraphContentToggle: React.FC<Props> = (props) => {
  return (
    <Grid css={toggleContainerStyled}>
      <Grid
        css={toggleLeftStyled(props.graphContent === "salesAmount")}
        onClick={() => props.toggleGraphContent("salesAmount")}
      >
        {SUMMARY_CONTENTS.salesAmount.name}
      </Grid>
      <Grid
        css={toggleRightStyled(props.graphContent === "salesCount")}
        onClick={() => props.toggleGraphContent("salesCount")}
      >
        {SUMMARY_CONTENTS.salesCount.name}
      </Grid>
    </Grid>
  );
};

export default React.memo(GraphContentToggle);

const toggleContainerStyled = css`
  display: flex;
  align-items: flex-start;
`;

const toggleLeftStyled = (isSelected: boolean) => css`
  cursor: pointer;
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid ${appColor.Border.black};
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 14px */
  ${isSelected ? toggleSelectedStyled : toggleUnselectedStyled}
`;

const toggleRightStyled = (isSelected: boolean) => css`
  cursor: pointer;
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid ${appColor.Background.black};
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 14px */
  ${isSelected ? toggleSelectedStyled : toggleUnselectedStyled}
`;

const toggleSelectedStyled = css`
  background: ${appColor.Background.black};
  color: ${appColor.Text.white};
`;

const toggleUnselectedStyled = css`
  background: ${appColor.Background.white};
  color: ${appColor.Text.primary};
`;
