import { css } from "@emotion/react";
import React from "react";

import { Grid } from "@mui/material";

import { appColor } from "@/styles/app-colors";

import { CustomInput } from "@/components/common/input/CustomInput";
import { DateRange } from "@/types/dashboard-types";
import dayjs from "dayjs";

type Props = { dateRange: DateRange; handleChangeDateRange: (dateRange: Partial<DateRange>) => void };

const DateRangePicker: React.FC<Props> = (props) => {
  const handleChangeStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const startDateDayjs = dayjs(e.target.value).startOf("d");
    if (startDateDayjs.isAfter(props.dateRange.endDate)) {
      const endDate = startDateDayjs.endOf("d").toDate();
      props.handleChangeDateRange({ startDate: startDateDayjs.toDate(), endDate });
      return;
    }
    props.handleChangeDateRange({ startDate: startDateDayjs.toDate() });
    return;
  };
  const handleChangeEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const endDateDayjs = dayjs(e.target.value).endOf("d");
    if (endDateDayjs.isBefore(props.dateRange.startDate)) {
      const startDate = endDateDayjs.startOf("d").toDate();
      props.handleChangeDateRange({ startDate, endDate: endDateDayjs.toDate() });
      return;
    }
    props.handleChangeDateRange({ endDate: endDateDayjs.toDate() });
    return;
  };

  return (
    <Grid css={rootStyled}>
      <Grid css={labelStyled}>対象期間</Grid>
      <Grid css={inputsContainerStyled}>
        <CustomInput
          type="date"
          css={inputStyled}
          value={dayjs(props.dateRange.startDate).format("YYYY-MM-DD")}
          onChange={handleChangeStartDate}
        />
        <CustomInput
          type="date"
          css={inputStyled}
          value={dayjs(props.dateRange.endDate).format("YYYY-MM-DD")}
          onChange={handleChangeEndDate}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(DateRangePicker);

const rootStyled = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const labelStyled = css`
  color: ${appColor.Text.primary};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
`;

const inputsContainerStyled = css`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const inputStyled = css`
  width: 100%;
  max-width: 352px;
  flex: 1 0 0;
`;
