import { css } from "@emotion/react";
import React from "react";

import { Grid } from "@mui/material";

import { mqMinScreen } from "@/utils/mediaQuery";

import TodayReservationList from "./organisms/TodayReservationList";
import UpperSection from "./organisms/UpperSection";

import { BaseLayout } from "@/components/common/layout/BaseLayout";

const DashBoardTemplate: React.FC = () => {
  return (
    <BaseLayout pageTitle="Top">
      <Grid css={rootStyled}>
        <UpperSection />
        <TodayReservationList />
      </Grid>
    </BaseLayout>
  );
};

export default React.memo(DashBoardTemplate);

const rootStyled = css`
  padding: 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  ${mqMinScreen("lg")} {
    padding: 24px 48px;
  }
  margin-top: 84px;
`;
